import React, { Fragment } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { getAllowedRoutes} from 'utils';
import { useSelector } from 'react-redux'
import { PrivateRoutesConfig } from 'config';
import { TopNav } from 'components/common';
import MapAllowedRoutes from 'routes/MapAllowedRoutes';

function PrivateRoutes() {
	const match = useRouteMatch('/app');

	let allowedRoutes = [];
	const auth = useSelector((state) => state.auth)

	if (auth.isAuthenticated){
	    allowedRoutes = getAllowedRoutes(PrivateRoutesConfig, auth.permissions);
	    console.log(">>> Allowed routes:");
	    console.log(allowedRoutes);
	}else{
	    console.log("[PrivateRoutes] user is not authenticated!")
	    return <Redirect to="/login" />;
	}

	return (
		<Fragment>
			<TopNav routes={allowedRoutes} prefix={match.path} className="bg-white" />
			<MapAllowedRoutes routes={allowedRoutes} basePath="/app" isAddNotFound />
		</Fragment>
	);
}

export default PrivateRoutes;