import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from 'redux/actions/auth'
import { NavLink } from 'react-router-dom'
import { Link, useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles'
import { Button, List, ListItem, Typography, Grid, Box } from '@material-ui/core'

import logo from '../../assets/img/logo/full_color_logo.png'

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    flexShrink: '0',
    height: 'auto',
    backgroundColor: theme.palette.grey.main,
    padding: '1rem',
    [theme.breakpoints.up('md')]: {
      padding: '1rem'
    }
  },
  logo: {
    width: '100%',
    maxWidth: '15rem'
  },
  socialMedia: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '.5rem'
    }
  },
  socialTitle: {
    fontSize: '1.2rem',
    lineHeight: '1.7rem',
    fontWeight: 500,
    marginBottom: '-10px'
  },
  list: {
    padding: '0',
    marginTop: '0',
    marginBottom: '2.8rem',
    fontSize: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1rem'
    }
  },
  listItem: {
    padding: '0',
    lineHeight: '1.5'
  },
  footerLink: {
    color: 'inherit',
    fontSize: '18px',
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      background: 'inherit'
    }
  },
  address: {
    fontSize: '1rem',
    fontWeight: 300,
    lineHeight: 1,
    display: 'block'
  }
}))


const UpdatedFooter = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
 let history = useHistory();

 function handleLogout() {
    dispatch(logout());
    history.push('/');
 }


  return (
    <footer className={classes.root}>
	  <Grid container justify="flex-end" >
	  	<Button onClick={() => handleLogout()}>Logout</Button>
	  </Grid>
    </footer>
  )
}

export default UpdatedFooter
