import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../redux/actions/auth.js'
import { useHistory } from 'react-router-dom'

// @material-ui/core components
import Nav from 'components/Nav/Nav'
import Toolbar from '@material-ui/core/Toolbar'

import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'

// @material-ui/icons
import Email from '@material-ui/icons/Email'
import {
  Typography,
  Grid,
  Button,
  Box,
  Input,
  Paper,
  Container,
  TextField,
  FormControl,
  InputLabel
} from '@material-ui/core'

// core components
import Page from '../../components/Page/Page'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    //marginTop: '5rem',
    //marginBottom: '5rem',
    lineHeight: '1'
  },
  title: {
    marginBottom: '1rem'
  },
  loginButton: {
    marginRight: '2rem'
  }
}))

export default function LoginPage(props) {
  const classes = useStyles()
  const { ...rest } = props
  const [cardAnimaton, setCardAnimation] = useState('cardHidden')

  const history = useHistory()
  const auth = useSelector((state) => state.auth)
  const alert = useSelector((state) => state.alert)
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const loginUser = (e) => {
    e.preventDefault()
    console.log(`checking if the user ${email} with the password ${password} exists.`)

    if (email === '' || password === '') {
      alert("Email or password fields can't be empty")
    } else {
      dispatch(login(email, password))
    }
  }

  if (auth.isAuthenticated) {
    console.log("[LoginPage] is authenticated, redirecting to /app/")
    history.push("/app");
  }

  return (
    <Page hideFooter={true}>
        <Nav />
        <Toolbar className={classes.toolbar} />

      <div className={classes.main}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container justify="center" alignitems="center">
            <Grid item xs={10}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography
                      className={classes.title}
                      color="primary"
                      variant="h3"
                    >
                      Login
                    </Typography>
                    <Typography variant="body1">
                      Let's Make Sustainable Attainable!
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <form className={classes.form} onSubmit={loginUser}>
                      <TextField
                        variant="outlined"
                        id="email"
                        labeltext="Email"
                        style={{ margin: 8 }}
                        placeholder="Email"
                        fullWidth
                        margin="dense"
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={email}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                      <TextField
                        variant="outlined"
                        labeltext="Password"
                        id="pass"
                        type="password"
                        style={{ margin: 8 }}
                        placeholder="Password"
                        fullWidth
                        margin="dense"
                        autoComplete="off"
                        defaultValue={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                      <Box mt={3}>
                        <Button
                          className={classes.loginButton}
                          type="submit"
                          simple="true"
                          size="large"
                        >
                          Login
                        </Button>
                      </Box>
                    </form>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Page>
  )
}
