import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import {useSelector} from 'react-redux'

// nodejs library to set properties for components
import PropTypes from 'prop-types'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Hidden, Container } from '@material-ui/core'

import { Link } from 'react-router-dom';
import { getAllowedRoutes } from 'utils';

import Page from 'components/Page/Page'
import BagScannerCard from './BagScannerCard';


const useStyles = makeStyles((theme) => ({
  container: {
	  justifyContent:'center',
	  textAlign:'center',
	  paddingLeft:theme.spacing(8),
	  paddingRight:theme.spacing(8),
	  marginBottom: theme.spacing(4),
	  maxWidth: "600px",
  },
}))



const CashoutPage = (props) => {
    const auth = useSelector(state => state.auth)
    const classes = useStyles()

  if(!auth.access_token){
    return <Redirect to="/login" />
  }

  function hasPermission(perm){
    for(var i=0;i<auth.permissions.length;i++){
        if(auth.permissions[i][0] == perm[0] && auth.permissions[i][1] == perm[1]){
            return true
        }
    }
    return false
  }

  return (
    <Page title="Cashout">
        <Container className={classes.container}>
                {hasPermission(["Admin","admin"]) &&
			<BagScannerCard />
                }
        </Container>
    </Page>
  )
}

export default CashoutPage

CashoutPage.propTypes = {
  children: PropTypes.node
}
