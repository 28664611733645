import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'assets/scss/material-kit-react.scss'
import { loadUserFromStorage, logout } from 'redux/actions/auth'
import axios from 'axios'
import Routes from 'routes'

import CustomAlert from 'components/CustomAlerts/CustomAlert.js';

// material ui theme
import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core'
import theme from './themes/theme'

function App() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(loadUserFromStorage());
  }, []);

  // intercept all requests and look for a 401 (unauthorized)
  axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err){
    if(err.response.status === 401){
        console.log("[Interceptor] YOU ARE UNAUTHORIZED!")
        // TODO: call login again to renew the token and then retry the failed request
        dispatch(logout())
        return Promise.reject(err)
    }
  })

    /* intercept _any_ request - this could be used to check the expiration-date and renew the token if it's close */
    /*
    axios.interceptors.request.use(
        request => { console.log("[Interceptor] Success"); return request; },
        error => {
                console.log("[Interceptor] Error")
                console.log(error)
                return error
            }
    );*/

  return (
    <MuiThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <CustomAlert />
      <Routes />
    </MuiThemeProvider>
  )
}

export default App
