import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// datamatrix scanner
import { useZxing } from 'react-zxing';

// Redux
import { useSelector } from 'react-redux';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Button, Card } from '@material-ui/core';
import scanner_icon from 'assets/img/scanner_icon.png'
import payout_icon from 'assets/img/payout_icon.png'
import already_paid_icon from 'assets/img/already_paid_icon.png'

import axios from 'axios';
import { ServerConfig } from '../../config';
import { configureConfigHeader } from 'utils/utils';


const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: `#007a9c`,
    padding: '1rem',
    borderRadius: 20,
    boxShadow: theme.shadows[5],
    maxWidth: '900px',
    [theme.breakpoints.up('md')]: {
      padding: '12px 24px 24px 24px',
    }
  },
  headerTitle: {
    fontWeight: 400,
    paddingLeft: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  iconAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    maxWidth: '700px',
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    // textAlign: 'justify',
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  link: {
    fontWeight: 400,
    fontSize: '1rem',
    backgroundColor: 'inherit',
    textTransform: 'inherit',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover,&:focus': {
      color: theme.palette.primary.contrastText,
      background: 'inherit',
    },
  },
  thankyou: {
    color: theme.palette.tertiary.main,
  },
  logo: {
    width: '3.5rem',
    height: 'auto',
    padding: '1px',
    filter: `drop-shadow(2px 2px 3px rgb(0 0 0 / 0.4))`,
  },
  cameraView: {
    position: "fixed",
    top: "0",
    left: "0",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    boxShadow: "none",
    overflow: "none",
    width: "100%",
    padding: "0",
    margin: "0",
    height: "100%",
    minWidth: "100%",
    minHeight: "100%",
    justifyContent: "center",
    zIndex: "1998",
    display:"flex"
  },
  cameraViewButton: {
    position: "fixed",
    top:"100px",
    right:"100px",
    zIndex: "999",
    display:"flex"
  },
  amount: {
	  fontSize: "64px"
  }
}));

export const BarcodeScanner = (props) => {
    const classes = useStyles(props);
    const [result, setResult] = useState("");

    const { ref } = useZxing({

      onResult(result) {
        console.log("READ DATAMATRIX:", result.getText())
        setResult(result.getText());
        props.codeSetter(result.getText());
        props.callback();
      },
    });
  
    return (
      <>
      <div className={classes.cameraView} >
        <video ref={ref} />
        <Button variant="contained" onClick={() => {props.callback(); props.cancel_callback()}} className={classes.cameraViewButton}>Close</Button>
        </div>
      </>
    );
  };

function BagScannerCard(props) {
  const token = useSelector((state) => state.auth.access_token)
  const classes = useStyles(props);
  const customer = useSelector((state) => state.auth.customer);

  const [scannerOpen, setScannerOpen] = useState(0);
  const [datamatrixCode, setDatamatrixCode] = useState("");
  const [message, setMessage] = useState("Please scan a receipt");
  const [amountDollars, setAmountDollars] = useState(-1.);

  const submitDatamatrixCode = () => {
    const config = {headers:{'Content-Type':'application/json'}}
    
    if(token){
        config.headers.Authorization = `Bearer ${token}`
    }
    
    const body = JSON.stringify({'task_id':datamatrixCode});

    let ret = axios
                .post(`${ServerConfig.SERVER_URL}/task/get_task_stats`, body, config)
                .then(response => {
			console.log(response);
			if(response.data.cashed_out){
				setMessage("Already paid out");
			}else{
				setMessage("Value:");
				setAmountDollars(response.data.redemption_amount_cents/100);
			}
                  })
                  .catch(error => {
                    console.log(error.response);
                    if (error.response.status == 404){
                        setMessage("This task does not exist");
                    }else{
                        setMessage("Oh oh! An error happened. Please contact Evtek.")
                    }
                    setDatamatrixCode("");
                  });
  };

	const payOut = () => {
	    const config = {headers:{'Content-Type':'application/json'}}
	    
	    if(token){
		config.headers.Authorization = `Bearer ${token}`
	    }
	    
	    const body = JSON.stringify({'task_id':datamatrixCode});

		console.log("Needs to be implemented!");

    let ret = axios
                .post(`${ServerConfig.SERVER_URL}/task/cash_out`, body, config)
                .then(response => {
			console.log(response);
			if(response.data.cashed_out){
				setMessage("Already paid out");
			}else{
				// all good!
				reset();
			}
                  })
                  .catch(error => {
                    console.log(error.response);
                    if (error.response.status == 404){
                        setMessage("This task does not exist");
                    }else{
                        setMessage("Oh oh! An error happened. Please contact Evtek.")
                    }
                    setDatamatrixCode("");
                  });



	}


	const reset = () => {
		setMessage("Please scan a receipt");
		setDatamatrixCode("");
		setAmountDollars(-1.);
	}

	useEffect(() => {
		if(datamatrixCode != ""){
			submitDatamatrixCode();
		}
	}, [datamatrixCode]);


  return (
    <Box p={2} >
	  <Grid container justifyContent="center" style={{'text-align':'center'}}>
	  <Grid item xs={12} justifyContent="center" style={{'text-align':'center'}}>
	<Typography variant="body">
	 	{message}
	</Typography>
	  </Grid>
	  </Grid>

        <Box p={2} style={{ 'width': '100%' }}>
            {scannerOpen == 1 && 
            	<BarcodeScanner callback={() => setScannerOpen(0)} cancel_callback={() => reset()} codeSetter={(code) => {setDatamatrixCode(code);}}/>
            }

            {(scannerOpen == 0 && datamatrixCode == "") &&
                <Button variant="contained" onClick={() => {setMessage(""); setDatamatrixCode(""); setScannerOpen(1)}} style={{backgroundColor:'#fff'}}>
			    <Grid container>
			    	<Grid xs={12}>
			    		<img src={scanner_icon} style={{'width':'100%', 'margin-left':'auto', 'margin-right':'auto'}} />
			   	</Grid>
			    	<Grid xs={12}>
			    		Scan
		   		</Grid>
			    </Grid>
		</Button>
            }

	    {(scannerOpen == 0 && datamatrixCode != "" && amountDollars < 0) &&
			    <>
		<Button variant="contained" onClick={() => reset()} style={{'background-color':'#900', 'margin-top':'20px', 'color':'white' }}>
		    <Grid container>
			<Grid xs={12}>
				<img src={already_paid_icon} style={{'width':'100%', 'margin-left':'auto', 'margin-right':'auto'}} />
			</Grid>
			<Grid xs={12}>
				Cancel
			</Grid>
		    </Grid>
		</Button>
			    </>
	    }

	    {amountDollars >= 0 &&
		<>
		<br />
		<Typography color="secondary" variant="h1" className={classes.amount}>
			    ${amountDollars.toFixed(2)}
		</Typography>

		<br />

		<Button variant="contained" onClick={() => payOut()} style={{'background-color':'#fff'}}>
		    <Grid container>
			<Grid xs={12}>
				<img src={payout_icon} style={{'width':'100%', 'margin-left':'auto', 'margin-right':'auto'}} />
			</Grid>
			<Grid xs={12}>
				Paid out
			</Grid>
		    </Grid>
		</Button>
		<Button variant="contained" onClick={() => reset()} style={{'background-color':'#f00', 'margin-top':'20px' }}>Cancel</Button>
		</>
	    }
        </Box>
    </Box>
  );
}

export default BagScannerCard;

BagScannerCard.propTypes = {
  children: PropTypes.node,
};
